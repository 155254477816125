<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="网点详情" />
    <div>
      <!-- <van-tabs v-model="activeName" color='#428dff'> -->
      <div class="top">
        <div>{{websiteName}}</div>
        <div>{{websiteNumber}}</div>
      </div>
      <!-- <van-tab title="日报" name="day"> -->
      <div v-if="activeName === 'day'">
        <div style="padding:16px;background-color:#fff;margin-top:10px">
          <div>{{$route.query.time}}</div>
          <van-row style="text-align:center" type='flex' justify='space-around'>
            <van-col span="8">
              <div class="title">安装电子支付</div>
              <div class="fontColor">{{list.azdzzf|numToFixed}}</div>
              <div class="title">较上周对比</div>
              <div v-if="list.szazdzzf<0" class="downStyle"><img src='../assets/icon/down.png'>{{-list.szazdzzf}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{list.szazdzzf}}%</div>
            </van-col>
            <van-col span=" 8">
              <div class="title">安装工单量</div>
              <div class="fontColor">{{list.azgdl}}</div>
              <div class="title">较上周对比</div>
              <div v-if="list.szazgdl<0" class="downStyle"><img src='../assets/icon/down.png'>{{-list.szazgdl}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{list.szazgdl}}%</div>
            </van-col>
            <van-col span="8">
              <div class="title">开工师傅组</div>
              <div class="fontColor">{{list.kgsfz}}</div>
              <div class="title">较上周对比</div>
              <div v-if="list.szkgsfz<0" class="downStyle"><img src='../assets/icon/down.png'>{{-list.szkgsfz}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{list.szkgsfz}}%</div>
            </van-col>
            <van-col span="8">
              <div class="title">维修电子支付</div>
              <div class="fontColor">{{list.wxdzzf|numToFixed}}</div>
              <div class="title">较上周对比</div>
              <div v-if="list.szwxdzzf<0" class="downStyle"><img src='../assets/icon/down.png'>{{-list.szwxdzzf}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{list.szwxdzzf}}%</div>
            </van-col>
            <van-col span="8">
              <div class="title">维修工单量</div>
              <div class="fontColor">{{list.wxgdl}}</div>
              <div class="title">较上周对比</div>
              <div v-if="list.szwxgdl<0" class="downStyle"><img src='../assets/icon/down.png'>{{-list.szwxgdl}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{list.szwxgdl}}%</div>
            </van-col>
            <van-col span="8">
              <div class="title">开工师傅</div>
              <div class="fontColor">{{list.kgsf}}</div>
              <div class="title">较上周对比</div>
              <div v-if="list.szkgsf<0" class="downStyle"><img src='../assets/icon/down.png'>{{-list.szkgsf}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{list.szkgsf}}%</div>
            </van-col>

          </van-row>
        </div>
      </div>
      <!-- </van-tab> -->
      <!-- <van-tab title="月报" name="month"> -->
      <div v-if="activeName === 'month'">
        <div v-for="(v,i) in listMonth" :key="i" style="padding:16px;background-color:#fff;margin-top:10px">
          <div>{{v.dmonth}}</div>
          <van-row style="text-align:center">
            <van-col span="12">
              <div class="title">安装电子支付</div>
              <div class="fontColor">{{v.insamount|numToFixed}}</div>
              <div class="title">较上月对比</div>
              <div v-if="v.linsratea<0" class="downStyle"><img src='../assets/icon/down.png'>{{-v.linsratea}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{v.linsratea ? v.linsratea:'0'}}%</div>
            </van-col>
            <van-col span="12">
              <div class="title">安装工单量</div>
              <div class="fontColor">{{v.insorder}}</div>
              <div class="title">较上月对比</div>
              <div v-if="v.linsrateo<0" class="downStyle"><img src='../assets/icon/down.png'>{{-v.linsrateo}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{v.linsrateo ? v.linsrateo:'0'}}%</div>
            </van-col>
            <van-col span="12">
              <div class="title">维修电子支付</div>
              <div class="fontColor">{{v.repamount|numToFixed}}</div>
              <div class="title">较上月对比</div>
              <div v-if="v.lrepratea<0" class="downStyle"><img src='../assets/icon/down.png'>{{-v.lrepratea}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{v.lrepratea ? v.lrepratea:'0'}}%</div>
            </van-col>
            <van-col span="12">
              <div class="title">维修工单量</div>
              <div class="fontColor">{{v.reporder}}</div>
              <div class="title">较上月对比</div>
              <div v-if="v.lreprateo<0" class="downStyle"><img src='../assets/icon/down.png'>{{-v.lreprateo}}%</div>
              <div v-else class="upStyle"><img src='../assets/icon/shangsheng.png'>{{v.lreprateo ? v.lreprateo:'0'}}%</div>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- </van-tab> -->
      <!-- </van-tabs> -->
    </div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      list: {},
      listMonth: [],
      websiteName: this.$route.query.name,
      websiteNumber: this.$route.query.website,
      activeName: this.$route.query.type,
    };
  },
  created() {
    // this.getList();
    // this.getListMonth();
    if (this.activeName === "day") {
      this.getList();
    } else {
      this.getListMonth();
    }
  },
  methods: {
    //获取数据-月报
    async getListMonth() {
      // let nowDate = new Date();
      // let date = {
      //   year: nowDate.getFullYear(), // 获取当前年份
      //   month: nowDate.getMonth() + 1, //获取当前月份
      // };
      // let start = date.year + "-01";
      // let end =
      //   date.year + "-" + (date.month > 9 ? date.month : "0" + date.month);
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
        startYearMonth: this.$route.query.time,
        endYearMonth: this.$route.query.time,
        website: this.$route.query.website,
        sumType: 1,
      };
      let res = await this.postRequest("/wechat-analysis/fees-month", params);
      this.listMonth = res.data;
    },
    //获取列表数据
    async getList() {
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "111111",
        type: "家用",
        startDate: this.$route.query.time,
        endDate: this.$route.query.time,
        website: this.$route.query.website,
      };
      let res = await this.postRequest(
        "/wechat-analysis/fees-day-wo-website",
        params
      );
      //   let list = res.data.for((v) => {
      //     this.websiteName = v.webName;
      //     this.websiteNumber = v.websit;
      //     return v.btype === "维修";
      //   });
      let list = {
        date: "",
        name: "",
        website: "",
        azdzzf: 0,
        szazdzzf: 0,
        azgdl: 0,
        szazgdl: 0,
        kgsfz: 0,
        szkgsfz: 0,
        wxdzzf: 0,
        szwxdzzf: 0,
        wxgdl: 0,
        szwxgdl: 0,
        kgsf: 0,
        szkgsf: 0,
      };
      res.data.forEach((v) => {
        let zzl = ((v.workers - v.wworkers) / v.wworkers) * 100;
        zzl = zzl.toFixed(2);
        if (v.btype === "材料") {
          list.azdzzf = v.amount;
          list.szazdzzf = v.wmr;
          list.azgdl = v.ctorder;
          list.szazgdl = v.wcr;
          list.kgsfz = v.workers;
          list.szkgsfz = zzl;
        }
        if (v.btype === "维修") {
          list.wxdzzf = v.amount;
          list.szwxdzzf = v.wmr;
          list.wxgdl = v.ctorder;
          list.szwxgdl = v.wcr;
          list.kgsf = v.workers;
          list.szkgsf = zzl;
        }
      });
      this.list = list;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  padding: 16px 0 16px 9px;
  font-weight: 700;
  font-size: 12px;
  background-color: #fff;
}
.title {
  font-size: 12px;
  color: #afafaf;
  margin-bottom: 5px;
  padding-top: 16px;
}
.num {
  font-size: 16px;
  color: #1296db;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
}
.num2 {
  font-size: 16px;
  color: #d81e06;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
}
</style>